import React from "react"
import VideoPlayer from "src/components/VideoPlayer"
import { AudioPlayerGroup } from "src/components/AudioPlayer"
import TitlePage from "src/components/TitlePage"
import CustomGrid, { Section, Group } from "src/components/CustomGrid"
import { GRIDS } from "src/util"
import { useArticles } from "src/hooks"
import parse from "html-react-parser"
import { BodyTextWrap, TitleContrast } from "src/atoms/typography"
import { Bookshelf } from "src/atoms"
import NextChapter from "../../components/NextChapter"
import { AnimatePresence, motion } from "framer-motion"

const Chapter3 = () => {
  const articles = useArticles({ articleCategory: 9048 })
  const { list } = articles

  if (list.length)
    return (
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 3.5 }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <TitlePage
              title={list[0]?.Title}
              subtitle={list[0].Lead}
              description={parse(list[0].Content)}
            />
            <Section>
              <CustomGrid>
                <div>
                  <TitleContrast>{list[1].Title}</TitleContrast>
                  <BodyTextWrap style={{ marginBottom: "3rem" }}>
                    {parse(list[1].Content)}
                  </BodyTextWrap>
                  <AudioPlayerGroup list={[list[2], list[5]]} />
                </div>
                <CustomGrid
                  photos={[list[3], list[4], list[6]]}
                  grid={GRIDS.p2}
                  heightRatio={1.26}
                />
              </CustomGrid>
            </Section>
            <Section>
              <CustomGrid>
                <div>
                  <TitleContrast>{list[7].Title}</TitleContrast>
                  <BodyTextWrap style={{ marginBottom: "3rem" }}>
                    {parse(list[7].Content)}
                  </BodyTextWrap>
                </div>
                <CustomGrid
                  photos={[list[8], list[9]]}
                  grid={GRIDS.p12}
                  heightRatio={0.57}
                />
                <div>
                  <AudioPlayerGroup list={list.slice(10, 12)} />
                </div>
                <VideoPlayer src={list[12].CustomFields[0].Value} />
              </CustomGrid>
            </Section>
            <Section>
              <CustomGrid>
                <TitleContrast>{list[13].Title}</TitleContrast>
                <div />
                <div>
                  <BodyTextWrap style={{ marginBottom: "3rem" }}>
                    {parse(list[13].Content)}
                  </BodyTextWrap>
                  <CustomGrid
                    photos={[list[15], list[16], list[18]]}
                    grid={GRIDS.p3}
                    heightRatio={0.84}
                  />
                </div>
                <div>
                  <AudioPlayerGroup list={[list[14], list[17], list[19]]} />
                </div>
              </CustomGrid>
            </Section>
            <Section>
              <CustomGrid
                photos={list.slice(20, 29)}
                grid={GRIDS.p4}
                heightRatio={0.77}
              />
            </Section>
            <Section>
              <CustomGrid>
                <div>
                  <TitleContrast>{list[29].Title}</TitleContrast>
                  <BodyTextWrap style={{ marginBottom: "1rem" }}>
                    {parse(list[29].Content)}
                  </BodyTextWrap>
                </div>
                <CustomGrid
                  photos={[list[30]]}
                  grid={GRIDS.p0}
                  heightRatio={0.64}
                />
              </CustomGrid>
              <Group marginT="4rem">
                <CustomGrid>
                  <AudioPlayerGroup list={list.slice(31, 36)} />
                </CustomGrid>
              </Group>
            </Section>
            <Section>
              <Bookshelf />
              <CustomGrid>
                <div>
                  <AudioPlayerGroup list={[list[37], list[39]]} />
                </div>
                <CustomGrid
                  photos={[list[38], list[40]]}
                  grid={GRIDS.p12}
                  heightRatio={0.69}
                />
              </CustomGrid>
              <Group marginT="4rem">
                <CustomGrid
                  photos={list.slice(41, 49)}
                  grid={GRIDS.p18}
                  heightRatio={0.77}
                  photoStyles={[{}, {}, {}, {}, { backgroundPosition: "top" }]}
                />
              </Group>
            </Section>
          </div>
          <NextChapter title="Twórczość" href="/tworczosc" />
        </motion.div>
      </AnimatePresence>
    )
  return null
}

export default Chapter3
