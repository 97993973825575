import React from "react";
import SEO from "../components/seo";
import Chapter3 from "src/views/Chapter3";

const IndexPage = () => {
  return (
    <>
      <SEO title="Przyjaźń w życiu poety" description="Wpływ innych ludzi na życie Krzysztofa Kamila Baczyńskiego – wybitnych literatów oraz szkolnych kolegów-harcerzy, z którymi zapisał się w historii pokolenia Kolumbów i walki w Powstaniu Warszawskim." image={require('images/seo/3.jpg')}/>
      <Chapter3 />
    </>
  );
};

export default IndexPage;
